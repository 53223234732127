import React from "react";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import PathwaysIntro from "../../components/pathways-intro";
import HeroPage from "../../components/hero-page";
import Pathways from "../../components/pathways";
import Seo from "../../components/seo";

const HospitalityLeisureEntertainment = ({ data }) => {

  return (
    <>
      <Seo
        title="Hospitality, Leisure and Entertainment in North Central Pennsylvania"
        description="The Hospitality, Leisure and Entertainment (HLE) cluster is comprised of industries that cater to tourism and recreation throughout the Commonwealth of Pennsylvania"
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="work"
        caption=""
        position="40%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Hospitality, Leisure and Entertainment</h2>

            <p>
              The Hospitality, Leisure and Entertainment (HLE) cluster is
              comprised of industries that cater to tourism and recreation
              throughout the Commonwealth.
            </p>
          </div>

          <div className="intro-text" style={{ alignSelf: "center" }}>
            <p>
              In addition to hotels and restaurants, this cluster includes
              casinos, golf courses, museums, racetracks, bowling centers and
              caterers.
            </p>
            <p>
              Many industries in this cluster are seasonal with part-time or
              tip-based employment.
            </p>
          </div>
        </section>

        <CareerLinkBox image={data.careerImage.sharp.image} caption="" />
      </main>

      <PathwaysIntro
        industry="Hospitality, Leisure and Entertainment"
        image={data.pathwaysImage.sharp.image}
        caption={
          <span>
            Perry Winkler Teaches Painting, Clearfield County
            <br />
            (image courtesy of{" "}
            <a href="https://pawilds.com" target="_new">
              PA Wilds
            </a>
            )
          </span>
        }
      />

      <Pathways pathway={data.pathways.pathway} />
    </>
  );
};

export const data = graphql`
  query {
    pathways: pathwaysYaml {
      pathway: manufacturing {
        name
        associate {
          name
          occupations
          salary
          skills
        }
        diploma {
          name
          occupations
          salary
          skills
        }
        certificate {
          name
          occupations
          salary
          skills
        }
        bachelor {
          name
          occupations
          salary
          skills
        }
      }
    }
    careerImage: file(
      relativePath: { eq: "nielson-caetano-salmeron-AQql9zLz9mk-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    pathwaysImage: file(
      relativePath: { eq: "ClearfieldCounty-PerryWinkler-TeachesPainting.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    heroImage: file(
      relativePath: { eq: "blake-wisz-GFrBMipOd_E-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "blake-wisz-GFrBMipOd_E-unsplash.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`;

export default HospitalityLeisureEntertainment;
